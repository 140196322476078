









































































































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipGoalsWizardEmergencyFundViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-emergency-fund-view-model';

@Component({
  name: 'FlagshipGoalsWizardEmergencyFund',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipGoalsWizardEmergencyFund extends Vue {
  flagship_wizard_emergency_model = Vue.observable(
    new FlagshipGoalsWizardEmergencyFundViewModel(this),
  );

  created() {
    this.flagship_wizard_emergency_model.initialize();
  }
}
