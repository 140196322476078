import Vue from 'vue';
import { v4 } from 'uuid';
import TYPES from '@/types';

import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';
import { maxValueRule, minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import CalculateMonthlyPaymentCalculationQuery
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/application/querys/calculate-monthly-payment-calculation-query';
import CalculateFixedTimeCalculationQuery
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/application/querys/calculate-fixed-time-calculation-query';
import CalculateCustomGoalMaximumInitialAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-initial-amount-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';

// Domain
import {
  CustomGoalMaximumInitialAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-initial-amount-dto';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipGoalsWizardEmergencyFundViewModel {
  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_INITIAL_AMOUNT_QUERY)
  private readonly calculate_maximum_initial_amount_query!:
    CalculateCustomGoalMaximumInitialAmountQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CALCULATE_EMERGENCY_FUND_MONTHLY_PAYMENT_CALCULATION_QUERY)
  private readonly create_monthly_payment_command!: CalculateMonthlyPaymentCalculationQuery;

  @Inject(TYPES.CALCULATE_EMERGENCY_FUND_FIXED_TIME_CALCULATION_QUERY)
  private readonly create_fixed_time_calculation_command!: CalculateFixedTimeCalculationQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly emergency_fund_investor_goal_state!: CreateEmergencyFundInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace =
    'components.flagship.flagship-goals.flagship_goals_wizard_emergency_fund';

  readonly view: Vue;

  slider_limit = 0;

  investor_profile_id = '';

  desired_amount = '0';

  emergency_fund_amount = 0;

  recommended_desired_amount?: number;

  savings_for_emergency_fund = '0';

  months_to_reach_goal = 1;

  currency_per_month = 0;

  fixed_time_adjusted = 0;

  accumulated_amount = 0;

  calculated_months = 0;

  months_of_monthly_incomes = 3;

  net_monthly_income = 0;

  max_slider_value = '100000';

  max_months_to_reach_goal_range = 48;

  min_months_to_reach_goal_range = 1;

  max_months_of_monthly_incomes = 6;

  min_months_of_monthly_incomes = 3;

  maximum_initial_amount = 0;

  is_valid_form = true;

  input_rules = {
    desired_amount: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    savings_for_emergency_fund: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$0.00 MXN', 0),
      (value: string) => maxValueRule(
        value.replace(/[^0-9.-]/g, ''),
        `$${this.maximum_initial_amount} MXN`,
        this.maximum_initial_amount,
      ),
    ],
  };

  timer?: NodeJS.Timer;

  strategy_selected = 'pocket';

  desired_amount_with_savings = '0';

  investor_goal_state = this.emergency_fund_investor_goal_state.state;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  strategies = [
    {
      label: this.translate('checkbox_2_3_1'),
      value: 'pocket',
    },
    {
      label: this.translate('checkbox_2_3_2'),
      value: 'other',
    },
  ];

  get currency_per_month_formatted() {
    return Math.trunc(this.currency_per_month).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get is_continue_btn_disabled() {
    return !(this.desired_amount! !== '$0 MXN' && this.months_to_reach_goal > 0
      && this.is_valid_form) || this.investor_goal_state.is_loading;
  }

  get user_has_other_inversion() {
    return this.parseCurrencyToNumber(this.savings_for_emergency_fund) !== 0
      && this.strategy_selected !== 'pocket';
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  delay = (calculate_amount_with_savings: boolean) => {
    this.investor_goal_state.is_loading = true;
    if (calculate_amount_with_savings) this.strategy_selected = 'pocket';
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      this.setSliderLimit();
      await this.monthlyPaymentCalculation(calculate_amount_with_savings);
    }, 2000);
  }

  clearSavingsForEmergencyFund = async () => {
    this.savings_for_emergency_fund = '0';
    await this.monthlyPaymentCalculation(true);
  }

  calculateSliderLimit = () => {
    this.slider_limit = (this.net_monthly_income * this.max_months_of_monthly_incomes);
  }

  setSliderLimit = () => {
    const months = Math.round((parseCurrencyToNumber(
      this.desired_amount,
    ) / this.net_monthly_income) * 100) / 100;

    if (parseCurrencyToNumber(this.desired_amount) > this.slider_limit) {
      this.max_months_of_monthly_incomes = Math.ceil(months);
    } else {
      this.max_months_of_monthly_incomes = 6;
    }

    this.months_of_monthly_incomes = Math.ceil(months);
    this.calculated_months = months;
  }

  calculateDesiredAmountWithSavings = () => {
    this.desired_amount_with_savings = String(this.parseCurrencyToNumber(this
      .desired_amount) - this.parseCurrencyToNumber(this.savings_for_emergency_fund));
  }

  monthlyPaymentCalculation = async (calculate_amount_with_savings: boolean) => {
    try {
      this.investor_goal_state.is_loading = true;
      await this.calculateMaximumInitialAmountAmount();
      if (this.is_valid_form) {
        // eslint-disable-next-line max-len
        if (calculate_amount_with_savings && this.user_has_other_inversion) {
          this.calculateDesiredAmountWithSavings();
        }
        const desired_amount = (this.user_has_other_inversion) ? this.parseCurrencyToNumber(this
          .desired_amount_with_savings) : this.parseCurrencyToNumber(this.desired_amount);

        const calculations = await this.create_monthly_payment_command.execute({
          desired_amount,
          // eslint-disable-next-line max-len
          initial_amount: (this.user_has_other_inversion) ? 0 : this.parseCurrencyToNumber(this.savings_for_emergency_fund),
          fixed_time_adjusted: this.months_to_reach_goal,
        });

        const {
          monthly_required_amount,
          accumulated_amount,
        } = calculations.emergency_fund_calculation;

        this.currency_per_month = Math.ceil(monthly_required_amount);
        this.accumulated_amount = accumulated_amount;
        this.max_slider_value = this.desired_amount.replace(/[^0-9.]/g, '');
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_id = investor_profile.id;
      this.net_monthly_income = investor_profile.net_monthly_income;
      // Multiplied by 3 to obtain a recommended amount for the goal (business rule).
      this.recommended_desired_amount = this.net_monthly_income * 3;
      this.desired_amount = String(Math.trunc(this.recommended_desired_amount));
      this.savings_for_emergency_fund = String(Math.trunc(investor_profile.emergency_fund_amount));
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  loadInvestorProfileEmergencyFund = async () => {
    if (this.emergency_fund_amount) {
      this.savings_for_emergency_fund = String(Math.trunc(this.emergency_fund_amount));
    }
    await this.monthlyPaymentCalculation(true);
  }

  getEmergencyFundInvestorGoalIfExists = async () => {
    try {
      if (this.investor_goal_state.emergency.desired_amount > 0) {
        this.months_of_monthly_incomes = this.investor_goal_state.emergency
          .months_of_monthly_incomes;
        this.months_to_reach_goal = this.investor_goal_state.emergency.fixed_time_adjusted;
        this.desired_amount = this.investor_goal_state.emergency.desired_amount.toString();
        this.max_slider_value = this.investor_goal_state.emergency.desired_amount.toString();
        this.savings_for_emergency_fund = this.investor_goal_state.emergency.initial_amount
          .toString();
      }
      await this.loadInvestorProfileEmergencyFund();
      await this.monthlyPaymentCalculation(true);
    } catch {
      this.message_notifier.showErrorNotification('errors.load');
    } finally {
      this.setSliderLimit();
    }
  };

  calculateNewEmergencyFund = () => {
    this.desired_amount = (this.net_monthly_income * this.months_of_monthly_incomes).toString();
    this.calculated_months = this.months_of_monthly_incomes;
  }

  createEmergencyFundInvestorGoal = async () => {
    try {
      this.investor_goal_state.emergency.id = v4();
      this.investor_goal_state.emergency.months_of_monthly_incomes = this.months_of_monthly_incomes;
      this.investor_goal_state.emergency.desired_amount = this.parseCurrencyToNumber(
        this.desired_amount,
      );
      this.investor_goal_state.emergency.fixed_time_adjusted = Math.ceil(
        this.months_to_reach_goal,
      );
      this.investor_goal_state.emergency.initial_amount = this.parseCurrencyToNumber(
        this.savings_for_emergency_fund,
      );
      this.investor_goal_state.emergency.monthly_required_amount = Math.ceil(
        this.currency_per_month,
      );
      this.investor_goal_state.emergency.accumulated_amount = this.accumulated_amount;
      return await this.updateInvestorProfile();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_emergency_fund_investor_goal'));
      return false;
    }
  }

  updateInvestorProfile = async () => {
    try {
      const investor_profile_dto: InvestorProfileDto = {
        id: this.investor_profile_id,
        emergency_fund_investor_goal: {
          months_of_monthly_incomes: this.investor_goal_state.emergency.months_of_monthly_incomes,
          initial_amount: this.investor_goal_state.emergency.initial_amount,
          desired_amount: this.investor_goal_state.emergency.desired_amount,
          monthly_required_amount: this.investor_goal_state.emergency.monthly_required_amount,
          fixed_time_adjusted: this.investor_goal_state.emergency.fixed_time_adjusted,
          accumulated_amount: this.investor_goal_state.emergency.accumulated_amount,
        },
      };
      await this.update_investor_profile_command.execute(investor_profile_dto);
      return true;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  };

  calculateMaximumInitialAmountAmount = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      const payload: CustomGoalMaximumInitialAmountCalculationDto = {
        monthly_required_amount: 0,
        fixed_time_adjusted: this.months_to_reach_goal,
        desired_amount: parseCurrencyToNumber(this.desired_amount),
        interest_rate: 0.055,
      };
      const calculation = await this
        .calculate_maximum_initial_amount_query.execute(payload);
      this.maximum_initial_amount = Math.trunc(
        parseFloat(calculation.maximum_initial_amount) * 100,
      ) / 100;
      if (this.view.$refs.form) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  onSubmit = async () => {
    this.investor_goal_state.is_loading = true;
    const stored = await this.createEmergencyFundInvestorGoal();
    this.investor_goal_state.is_loading = false;
    if (stored) {
      this.view.$emit('nextStep');
    }
  };

  async initialize() {
    this.investor_goal_state.is_loading = true;
    await this.getInvestorProfile();
    this.calculateSliderLimit();
    await this.getEmergencyFundInvestorGoalIfExists();
    this.calculateDesiredAmountWithSavings();
    this.investor_goal_state.is_loading = false;
  }
}
